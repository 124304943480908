<template>
	<main id="container" class="page-container bg-wave1 promotion">
		<div class="page-header">
			<div class="container">
				<div class="display-flex is-between">
					<page-header title="홍보물" />
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="container">
				<div class="page-sub-header">
					<h3 class="page-sub-title"><span class="prima">동행축제를 알리는</span> 홍보물입니다</h3>
				</div>
				<div class="promotion-banner-img">
					<div class="promotion-banner-img__img">
						<img src="@/assets/img/information/promotion_banner_img.svg" alt="살맛나는 행복쇼핑 동행축제 2024 K-SHOPPING FESTA 2024.9.28-9.28" />
					</div>
					<div class="promotion-banner-img__bottom">
						<img src="@/assets/img/information/promotion_banner_bottom.png" alt="중소벤쳐기업부 중소기업유통센터 소상공인시장진흥공단" />
					</div>
				</div>
				<div class="promotion-button-container">
					<span class="promotion-button-title">홍보물을 자유롭게 활용하세요!</span>
					<div class="promotion-buttons">
						<a class="promotion-button" :disabled="isLoading" @click="onClickDnloadAtch($event, atchMeta.STNDRD)">
							<span class="btn-txt">기본형 홍보물 다운로드</span>
						</a>
						<a class="promotion-button" :disabled="isLoading" @click="onClickDnloadAtch($event, atchMeta.ENG)">
							<span class="btn-txt">영문형 홍보물 다운로드</span>
						</a>
						<a class="promotion-button" :disabled="isLoading" @click="onClickDnloadAtch($event, atchMeta.PR)">
							<span class="btn-txt">홍보물 활용가이드</span>
						</a>
					</div>
				</div>
				<div class="promotion-prection-container">
					<ul class="promotion-prection-list">
						<li class="promotion-prection-list-li">
							<div class="promotion-prection-item">
								<div class="prection-item-title">
									<span>광고물<br />활용범위</span>
								</div>
								<div class="prection-item-text">
									<span class="prection-item-desc">2024 동행축제 참가 대, 중, 소상공인 오프라인 광고물 (포스터, 현수막 등)</span>
									<span class="prection-item-desc">2024 동행축제 참가 대, 중, 소상공인 오프라인 광고물 (웹배너, 웹포스터 등)</span>
									<span class="prection-item-desc">대기업의 개별 브랜드는 활용 불가합니다.</span>
									<span class="prection-item-desc">모델 사진이 활용된 시안은 이미지 파일 (.jpg)로만 제공되며, 디자인을 합성하거나 변형하여 사용하실 수 없습니다.</span>
								</div>
							</div>
						</li>
						<li class="promotion-prection-list-li">
							<div class="promotion-prection-item">
								<div class="prection-item-title">
									<span>주의사항</span>
								</div>
								<div class="prection-item-text">
									<span class="prection-item-desc">2024 동행축제 타이틀과 모델을 별도로 사용할 수 없으며, 브랜드나 제품의 단독 홍보를 위한 광고물로는 사용할 수 없습니다.</span>
									<span class="prection-item-desc">광고물은 2024 동행축제 기간에만 사용 가능하며 행사 기간 후에는 회수 부탁드립니다.</span>
									<span class="prection-item-desc">위 사항을 위반하거나 불이행 하는 경우 법적 조치가 이루어지므로 주의가 필요합니다.</span>
								</div>
							</div>
						</li>
						<li class="promotion-prection-list-li">
							<div class="promotion-prection-item">
								<div class="prection-item-title">
									<span>문의처</span>
								</div>
								<div class="prection-item-text">
									<span class="prection-item-desc">참여업체문의 : 02-6678-9881, 9882, 9883, 9885, 9886</span>
									<!-- <span class="prection-item-desc">홍보물활용관련 문의 : 02-6951-0624</span> -->
									<span class="prection-item-desc">문의가능시간 : 평일 10:00 ~ 17:00</span>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import PageHeader from '@/components/content/PageHeader';
export default {
	name: 'Promotion',
	components: { PageHeader },
	data: () => ({
		isLoading: false,
		atchMeta: {
			STNDRD: {
				ptUrl: 'https://gov-api-storage.cloud.toast.com/v1/AUTH_09c310d7e51240e3b81c870358a31074/test/admin/ntc/atch/542/2024/08/19/c7d5292b.zip',
				fileNm: '2024 동행축제_국문형_5종_9월_240710',
			},
			ENG: {
				ptUrl: 'https://gov-api-storage.cloud.toast.com/v1/AUTH_09c310d7e51240e3b81c870358a31074/test/admin/ntc/atch/542/2024/08/19/d0ff986b.zip',
				fileNm: '2024 동행축제_영문형_5종_9월_240710',
			},
			PR: {
				ptUrl: 'https://gov-api-storage.cloud.toast.com/v1/AUTH_09c310d7e51240e3b81c870358a31074/test/admin/ntc/atch/542/2024/08/19/ace7d2d6.pdf',
				fileNm: '2024 동행축제 키비주얼 활용가이드-1',
			},
		},
	}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		onClickDnloadAtch(e, typ) {
			this.isLoading = true;

			let link = document.createElement('a');
			try {
				link.href = typ.ptUrl;
				link.setAttribute('download', typ.fileNm);
				document.body.appendChild(link);
				link.click();
			} catch (err) {
				console.error(err);
			} finally {
				document.body.removeChild(link);
				this.isLoading = false;
			}
		},
	},
};
</script>
